/* eslint-disable max-len */
import React, {useEffect, lazy, useState} from "react"
import "./defaultContainer.css"
import {useTranslation} from "react-i18next"
import { useFormik } from "formik"
import * as Yup from "yup"
import {connect, useDispatch} from "react-redux"
import moment from "moment"
import {Checkbox} from "antd"
import {useLocation} from "react-router-dom"
import * as queryString from "querystring"
import { RootState } from "../../redux/reducers/rootReducer"
import FormikInput from "../inputs/FormikInput"
import { changeStep, changeValues } from "../../redux/actions/stepsActions"
import { ibanIsValid } from "../../utils/iban"
import { parsePhoneNumber } from "libphonenumber-js"
import FormikTextField from "../inputs/formikTextField/FormikTextField"
import Switch from "./Switch"



interface CustomProps {
	steps?: any,
	setButtonDisabled: (state:boolean) => void,
	id?: any
	couponing?: boolean
}

function LocalDefaultContainer({steps, setButtonDisabled, id, couponing}:CustomProps) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const routeParams = Object.fromEntries(searchParams.entries())

	const [multiCountriesLayout, setMultiCountriesLayout] = useState(false)

	useEffect(() => {
		const parsedSearch = queryString.parse(location.search)
		if (parsedSearch && parsedSearch["?multi"]) {
			setMultiCountriesLayout(true)
		}
		let name = ""
		if (routeParams.firstName && routeParams.lastName.length > 0) {
			name = routeParams.firstName
		}
		if (routeParams.lastName && routeParams.lastName.length > 0) {
			name = name + " " + routeParams.lastName
		}
		if (name.length > 0) {
			formik.setFieldValue("firstContactRowName", name)
		}
		if (routeParams.email && routeParams.email.length > 0) {
			formik.setFieldValue("firstContactRowEmail", routeParams.email)
		}
	}, [])

	// Get current language to show different text

	const validateYum = (value:string, isEmail = false) => {
		return Yup.string().when(value, {
			is: (value:any) => value?.length > 0,
			then: isEmail
				? Yup.string().required(t("warnings.validation.required"))
					.email(t("warnings.validation.email"))
				: Yup.string().required(t("warnings.validation.required")),
			otherwise: Yup.string().notRequired()
		})
	}

	const formik = useFormik<any>({
		initialValues: {
			iban: "",
			accountHolder: "",
			country: ["at"],
			partner: "",
			offer: "",
			startDate: "",
			endDate: "",
			firstContactRowName: "",
			firstContactRowEmail: "",
			secondContactRowName: "",
			secondContactRowEmail: "",
			thirdContactRowName: "",
			thirdContactRowEmail: "",
			specialOffer: false,
			localMerchantName: "",
			localMerchantUID: "",
			localMerchantAddress: "",
			localMerchantWebpage: "",
			phoneNumber: "",
			authorizedDecisionMaker: false,
			acceptTermsConditions: false,
			confirmBindingOrder: false,
		},
		validationSchema: Yup.object().shape({
			accountHolder: !couponing ? 
				Yup.string().required(t("warnings.validation.required")) 
				: Yup.mixed().nullable(),
			iban: couponing ? 
				Yup
					.string()
					.nullable()
					.test("is-valid-iban", (meta) => {
						if(!meta.originalValue) return ""
						const isValid = ibanIsValid(meta.originalValue)

						if(!isValid.valid){
							return t(`warnings.validation.iban.${isValid.details}`)
						}

					} ,(value)=>{
						if(value === undefined) return true
						if(value === null ) return true
						
						const isValid = ibanIsValid(value)
						return isValid.valid
					}) 
				: 
				Yup.mixed().nullable(),
			country: Yup.array().required(t("warnings.validation.required")).min(1),
			partner: Yup.string().required(t("warnings.validation.required")),
			authorizedDecisionMaker: Yup.boolean().oneOf([true], t("warnings.validation.required")).required(t("warnings.validation.required")), 
			acceptTermsConditions: Yup.boolean().oneOf([true], t("warnings.validation.required")).required(t("warnings.validation.required")), 
			confirmBindingOrder: Yup.boolean().oneOf([true], t("warnings.validation.required")).required(t("warnings.validation.required")),
			offer: !couponing ? Yup
				.number()
				.typeError(t("warnings.validation.number"))
				.min(10, t("warnings.validation.numberTooLow", {number: "10%"}))
				.max(99, t("warnings.validation.numberTooHigh", {number: "99%"}) )
				.required(t("warnings.validation.required"))
				:
				Yup.mixed().required(t("warnings.validation.required")),
			firstContactRowName: Yup.string().required(t("warnings.validation.required")),
			firstContactRowEmail: Yup.string().required(t("warnings.validation.required"))
				.email(t("warnings.validation.required")),
			secondContactRowName: validateYum("secondContactRowEmail"),
			secondContactRowEmail: validateYum("secondContactRowName", true),
			thirdContactRowName: validateYum("thirdContactRowEmail"),
			thirdContactRowEmail: validateYum("thirdContactRowName", true),
			localMerchantUID: Yup.string().required(t("warnings.validation.required")),
			localMerchantName: Yup.string().required(t("warnings.validation.required")),
			localMerchantAddress: Yup.string().required(t("warnings.validation.required")),
			localMerchantWebpage: Yup.string().required(t("warnings.validation.required")),
			phoneNumber: !couponing ? Yup.string().required(t("warnings.validation.required")).test(
				"phoneNumber",
				t("warnings.validation.phoneNumber"),
				(value, context)=>{
					try {
						if(!value) return false
						const parsed = parsePhoneNumber(value)
						return parsed.isValid()
					} catch (error) {
						return false
					}

				}
			) : Yup.mixed().required(t("warnings.validation.required"))
		}, [
			["secondContactRowName", "secondContactRowEmail"],
			["secondContactRowEmail", "secondContactRowName"],
			["thirdContactRowName", "thirdContactRowEmail"],
			["thirdContactRowEmail", "thirdContactRowName"]
		]),
		onSubmit: () => {
			// Block submit
		},
	})

	const [stateIsInitialized, setStateIsInitialized] = useState(false)

	useEffect(() => {
		if (!steps.values[0].startDate && !stateIsInitialized) {
			formik.setFieldValue("startDate", moment(Date.now()).format("YYYY-MM-DD"))
			formik.setFieldValue("endDate", moment(Date.now()).format("YYYY-MM-DD"))
		}
	}, [steps])

	// Initialize form values
	useEffect(() => {
		if (!stateIsInitialized && steps.values[0].startDate) {
			Object.keys(steps.values[0]).forEach((key:string) => {
				formik.setFieldValue(key, steps.values[0][key], true)
				setTimeout(() => formik.setFieldTouched(steps.values[0][key], true))
			})
		}
		setStateIsInitialized(true)
	}, [steps])

	// Submit form handler
	const handleFormSubmit = (e:any) => {
		e.preventDefault()
		if (formik.isValid && formik.dirty) {
			dispatch(changeStep(1))
		}
	}

	const [formWithIdIsInitialized, setFormWithIdIsInitialized] = useState(false)

	// Save values to state
	useEffect(() => {
		if (stateIsInitialized) {
			const stateCopy = [...steps.values]
			stateCopy[steps.step] = formik.values
			dispatch(changeValues(stateCopy, 0))
		}

	}, [formik.values])

	// Handle Date Picker changes
	const handleDateChange = (dates:any) => {
		formik.setFieldValue("startDate", moment(dates[0].startDate).format("YYYY-MM-DD"))
		formik.setFieldValue("endDate", moment(dates[0].endDate).format("YYYY-MM-DD"))
	}

	const getContactFieldName = (idx:number) => {
		switch (idx) {
		case 0:
			return {
				name: "firstContactRowName",
				email: "firstContactRowEmail"
			}
		case 1:
			return {
				name: "secondContactRowName",
				email: "secondContactRowEmail"
			}
		case 2:
			return {
				name: "thirdContactRowName",
				email: "thirdContactRowEmail"
			}
		}
	}

	useEffect(() => {
		if (id && steps.values[0].partner && !formWithIdIsInitialized) {
			setFormWithIdIsInitialized(true)
			setStateIsInitialized(true)
			Object.keys(steps.values[0]).forEach((key:string) => {
				formik.setFieldValue(key, steps.values[0][key], true)
				setTimeout(() => formik.setFieldTouched(steps.values[0][key], true))
			})
			// Contact fields
			steps.values[0].contact.map((contactField:any, idx:number) => {
				formik.setFieldValue(
					getContactFieldName(idx)?.name || "",
					steps.values[0].contact[idx].name,
					true
				)
				setTimeout(() => formik.setFieldTouched(
					getContactFieldName(idx)?.email || "", true))
				formik.setFieldValue(
					getContactFieldName(idx)?.email || "",
					steps.values[0].contact[idx].email,
					true
				)
				setTimeout(() => formik.setFieldTouched(
					getContactFieldName(idx)?.email || "", true))
			})

		}
	}, [steps.values])

	useEffect(() => {
		setButtonDisabled(!(formik.isValid && formik.dirty))
	}, [formik.dirty, formik.isValid])

	const handleCountrySelect = (event:any, country: "de" | "at") => {
		const countriesCopy = [...formik.values.country]
		let filteredArray:string[] = []
		if (countriesCopy.includes(country)) {
			filteredArray = countriesCopy.filter(function(val:string) {
				return val !== country
			})
		} else {
			countriesCopy.push(country)
			filteredArray = countriesCopy
		}
		formik.setFieldValue("country", filteredArray, true)
	}

	return (
		<form onSubmit={(e:any) => handleFormSubmit(e)}>
			<div className="default_container">
				<div data-testid="default_partner_container">
					<FormikInput
						errors={formik.errors.partner}
						touched={formik.touched.partner}
						htmlFor="partner"
						name="partner"
						value={formik.values.partner}
						disabled={false}
						handleChange={formik.handleChange}
						label={t("default_container.form.partner_label")}
						onBlur={formik.handleBlur}
					/>

					<div style={{height: "1px"}} />

					<p className="simple_label">
						{t("default_container.form.offer_label")}
					</p>
					<p className="sub_label" style={{marginBottom: "5px"}}>
						{couponing
							? t("default_container.form.offer_sub_label_couponing")
							: t("default_container.form.offer_sub_label")
						}
					</p>
					<FormikInput
						htmlFor="offer"
						name="offer"
						errors={formik.errors.offer}
						touched={formik.touched.offer}
						value={formik.values.offer}
						disabled={false}
						handleChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<div style={{height: "1px"}} />

					{!couponing &&
						<>
							<p className="simple_label" style={{ maxWidth: "500px" }}>
								<input
									name="specialOffer"
									type="checkbox"
									checked={formik.values.specialOffer}
									onChange={formik.handleChange}
									style={{ marginRight: "8px" }}
								/>
								{t("default_container.form.special_offer")}
								<span>
									{t("default_container.form.special_offer_text")}
								</span>
							</p>
							<div style={{ height: "20px" }} />
						</>
					}

					<p className="simple_label" style={{maxWidth: "500px"}}>
						{t("default_container.form.contact_title")}
						<span style={{ margin: 0}}>{t("default_container.form.contact_title_span")}</span>
					</p>
					<div style={{height: "1px"}} />

					{/* <div key={"0"}>
						<p className="contact_container_title">
							{t("default_container.form.contact_title_label")}{1}
						</p>
						<div className="contact_container">
							<div>
								<p className="sub_label" style={{marginBottom: "5px"}} style={{margin: 0}}>
									{t("default_container.form.name_label")}
								</p>
								<FormikInput
									htmlFor="name"
									name={getContactFieldName(0)?.name || ""}
									errors={formik.errors[getContactFieldName(0)?.name || ""]}
									touched={formik.touched[
										getContactFieldName(0)?.name || ""
									]}
									value={formik.values[getContactFieldName(0)?.name || ""]}
									disabled={false}
									handleChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>
							<div>
								<p className="sub_label" style={{marginBottom: "5px"}} style={{margin: 0}}>
									{t("default_container.form.email_label")}
								</p>
								<FormikInput
									htmlFor="email"
									name={getContactFieldName(0)?.email || ""}
									errors={formik.errors[
										getContactFieldName(0)?.email || ""
									]}
									touched={formik.touched[
										getContactFieldName(0)?.email || ""
									]}
									value={formik.values[getContactFieldName(0)?.email || ""]}
									disabled={false}
									handleChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>
						</div>
					</div> */}
					{Array.from(Array(2).keys()).map((ite:any, idx:number) => (
						<div key={idx.toString()}>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", textAlign: "end", gap: "5px", width: "100%", maxWidth: "500px"}}>
								<p className="contact_container_title" >{idx+1}.</p>
								<div style={{ flexGrow: 1}}>
									{formik.errors[getContactFieldName(idx)?.name || ""] && formik.touched[
										getContactFieldName(idx)?.name || ""
									] && <p className="input_error" style={{margin: 0}}>{formik.errors[getContactFieldName(idx)?.name || ""]}</p>}
									<FormikInput
										placeholder={`${t("default_container.form.name_label")} ${!idx ? "*" : ""}`}
										htmlFor="name"
										name={getContactFieldName(idx)?.name || ""}
										errors={formik.errors[getContactFieldName(idx)?.name || ""]}
										hideErrorMessage={true}
										touched={formik.touched[
											getContactFieldName(idx)?.name || ""
										]}
										value={formik.values[getContactFieldName(idx)?.name || ""]}
										disabled={false}
										handleChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</div>
								<div  style={{ flexGrow: 1}}>
									{formik.errors[getContactFieldName(idx)?.email || ""] && formik.touched[
										getContactFieldName(idx)?.email || ""
									] && <p className="input_error" style={{margin: 0}}>{formik.errors[getContactFieldName(idx)?.email || ""]}</p>}
									<FormikInput
										placeholder={`${t("default_container.form.email_label")} ${!idx ? "*" : ""}`}
										htmlFor="email"
										name={getContactFieldName(idx)?.email || ""}
										errors={formik.errors[
											getContactFieldName(idx)?.email || ""
										]}
										hideErrorMessage={true}
										touched={formik.touched[
											getContactFieldName(idx)?.email || ""
										]}
										value={formik.values[getContactFieldName(idx)?.email || ""]}
										disabled={false}
										handleChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</div>
							</div>
						</div>
					))}

					{
						couponing && (<> 
							<div style={{height: "1px"}} />
							<p className="simple_label" style={{maxWidth: "500px"}}>
								{t("default_container.form.bank_details")}:
							</p>
							<FormikInput
								htmlFor="accountHolder"
								name="accountHolder"
								placeholder={t("default_container.form.accountHolder_label")}
								errors={formik.errors.accountHolder}
								touched={formik.touched.accountHolder}
								value={(formik.values.accountHolder as string)}
								disabled={false}
								handleChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
							{/* <p className="sub_label" style={{margin: 0, fontSize: "10px"}}>
								{couponing
									? t("default_container.form.iban_sub_label_couponing")
									: t("default_container.form.iban_sub_label")
								}
							</p> */}
							
							<FormikInput
								htmlFor="iban"
								name="iban"
								placeholder={t("default_container.form.iban_label")}
								errors={formik.errors.iban}
								touched={formik.touched.iban}
								value={(formik.values.iban as string).toUpperCase()}
								disabled={false}
								handleChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</>)
					}
					<div style={{height: "1px"}} />
					
					<p className="simple_label">
						{t("text_form.merchant_title")}
					</p>
					<div className="merchant_container">
						<FormikInput
							handleChange={formik.handleChange}
							value={formik.values.localMerchantName}
							name="localMerchantName"
							htmlFor="localMerchantName"
							disabled={false}
							errors={formik.errors.localMerchantName}
							touched={formik.touched.localMerchantName}
							onBlur={formik.handleBlur}
							placeholder={t("text_form.local_merchant_text_1")}
						/>
					</div>
					<div className="merchant_container">
						<FormikInput
							handleChange={formik.handleChange}
							value={formik.values.localMerchantUID}
							name="localMerchantUID"
							htmlFor="localMerchantUID"
							disabled={false}
							errors={formik.errors.localMerchantUID}
							touched={formik.touched.localMerchantUID}
							onBlur={formik.handleBlur}
							placeholder={"UID*"}
						/>
					</div>
					{/* REQUIRED */}
					<div style={{height: "5px"}} />
					<div className="merchant_container">
						<FormikTextField
							handleChange={formik.handleChange}
							value={formik.values.localMerchantAddress}
							name="localMerchantAddress"
							errors={formik.errors.localMerchantAddress}
							touched={formik.touched.localMerchantAddress}
							onBlur={formik.handleBlur}
							placeholder={t("text_form.local_merchant_text_2")}
						/>
					</div>
					<FormikInput
						handleChange={formik.handleChange}
						value={formik.values.localMerchantWebpage}
						name="localMerchantWebpage"
						htmlFor="localMerchantWebpage"
						errors={formik.errors.localMerchantWebpage}
						touched={formik.touched.localMerchantWebpage}
						onBlur={formik.handleBlur}
						disabled={false}
						placeholder={t("text_form.local_merchant_text_3")}
					/>

					{
						couponing ? <>
							<FormikInput
								handleChange={formik.handleChange}
								value={formik.values.phoneNumber}
								name="phoneNumber"
								htmlFor={"phoneNumber"}
								errors={formik.errors.phoneNumber}
								touched={formik.touched.phoneNumber}
								onBlur={formik.handleBlur} 
								disabled={false}
								placeholder={t("text_form.phoneNumber")}
							/>
						</> : 
							null
					}
					<div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingTop: "10px", fontSize: "12px", lineHeight: "17px"}}>
						

						{
							// TODO: WHAT LINK HERE + REQUIRED MARKS
						}
						<div style={{ 
							display: "flex", 
							width: "500px", 
							alignItems: "center", 
							gap: "5px",
							justifyContent: "space-between"
						}}> 
							<div style={{ flex: 1 }}>
								{t("main_form.form.confirmation")}
							</div>
							<Switch 
								name="authorizedDecisionMaker" 
								checked={formik.values.authorizedDecisionMaker} 
								onChange={formik.handleChange} 
							/> 
						</div>  
						<div style={{ 
							display: "flex", 
							width: "500px", 
							alignItems: "center", 
							gap: "5px",
							justifyContent: "space-between"
						}}> 
							<div style={{ flex: 1,}}>
								{t("main_form.form.terms1")} {" "} 
								<a style={{ color: "#1890ff"}} href="https://www.corplife.at/agb-gutscheinverkauf/  ">{t("main_form.form.terms_link")} </a>
								{t("main_form.form.terms2")}
							</div>
							<Switch 
								name="acceptTermsConditions" 
								checked={formik.values.acceptTermsConditions} 
								onChange={formik.handleChange} 
							 /> 
						</div>  

						<div style={{ 
							display: "flex", 
							width: "500px", 
							alignItems: "center", 
							gap: "5px",
							justifyContent: "space-between"
						}}> 
							<div style={{ flex: 1 }}>
								{t("main_form.form.binding_confirmation")}
							</div>
							<Switch 
								name="confirmBindingOrder" 
								checked={formik.values.confirmBindingOrder} 
								onChange={formik.handleChange} 
		 />
						</div> 
					</div>


				</div>
				<div className="right_container">
					<div className="iframe-container">

						<iframe id='youtubeVideo' title="Corplife Information" width={300} height={200} src="https://www.youtube.com/embed/fkgR7SOyIs0" ></iframe>
					</div>
					<div>
						{multiCountriesLayout
							? <>
								<h3
									style={{marginBottom: 0}}
								>
									{t("default_container.form.countries_title")}
								</h3>
								<p
									style={{fontSize: "14px", marginTop: 0, marginBottom: 0}}
								>
									{t("default_container.form.countries_text")}
								</p>

								<Checkbox
									onChange={(e:any) => handleCountrySelect(e, "at")}
									checked={formik.values.country.includes("at")}
								>
									{t("default_container.form.countries_selection_text",
										// eslint-disable-next-line max-len
										{country: t("default_container.form.countries_selection_text_aut")}
									)}
								</Checkbox>

								<Checkbox
									style={{marginLeft: 0, marginBottom: "10px"}}
									onChange={(e:any) => handleCountrySelect(e, "de")}
									checked={formik.values.country.includes("de")}
								>
									{t("default_container.form.countries_selection_text",
										// eslint-disable-next-line max-len
										{country: t("default_container.form.countries_selection_text_de")}
									)}
								</Checkbox>
							</>
							: null
						}

						{!couponing &&
							<>
								<p className="simple_label" data-testid="default_calendar_title">
									{t("default_container.form.offer_expire_label")}
								</p>
								<p
									className="sub_label"
									style={{margin: 0}}
									data-testid="default_calendar_sub_label"
								>
									{t("default_container.form.local_offer_expire_sub_label")}
								</p>
							</>
						}
					</div>
				</div>
			</div>
		</form>
	)
}

const mapStateToProps = (state:RootState) => {
	return {
		steps: state.steps
	}
}

export default connect(mapStateToProps, null)(LocalDefaultContainer)
