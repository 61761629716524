/* eslint-disable max-len */
import React, {lazy, useEffect, useState} from "react"
import "./Main.css"
import {connect, useDispatch} from "react-redux"
import {RootState} from "../../redux/reducers/rootReducer"
import {useTranslation} from "react-i18next"
import {useLocation, useParams} from "react-router-dom"
import {
	changeStep,
	changeValues,
	clearValuesMessages, getValues,
	sendValues, updateValues
} from "../../redux/actions/stepsActions"
import {toast} from "react-toastify"
import CustomToastContainer from "../toast/Toast"
import moment from "moment"
import {BeatLoader} from "react-spinners"
import InfoScreen from "../infoScreen/InfoScreen"
import Footer from "../footer/Footer"
import LocalDefaultContainer from "./LocalDefaultContainer"
import LocalMainForm from "./LocalMainForm"
import LocalTextForm from "./LocalTextForm"
import LocalInfoScreen from "./LocalInfoScreen"
import { encryptString, PUBLIC_KEYS, verifyString } from "../../utils/crypto"
import { PopupWidget } from "react-calendly"
import Switch from "./Switch"

const Button = lazy(() => import("../button/Button"))
const LocalExamplePage = lazy(() => import("./LocalExamplePage"))
const SuccessContainer = lazy(() => import("../mainComponents/successContainer/successContainer"))

interface CustomProps {
	steps?: any;
	sendFormState?: string;
	props?: any;
	storedValues?: any;
	serverMessages?: string;
	types?: number;
	business?: boolean;
	couponing?: boolean;
}

function LocalMain({
	steps,
	sendFormState,
	storedValues,
	serverMessages,
	types,
	business,
	couponing
}:CustomProps) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const params = useParams()

	useEffect(() => {
		if (steps.step === 3) {
			dispatch(changeStep(0))
		}
	}, [location])

	// Save form id if url /:id
	const [formId, setFormId] = useState("")
	useEffect(() => {
		if (params && params.id) {
			setFormId(params.id)
			dispatch(getValues(params.id))
		}
	}, [params])

	const [buttonDisabled, setButtonDisabled] = useState(true)

	const [earlySubmit, setEarlySubmit] = useState(false)

	const earlySubmitTransformer = (number:any) => {
		if(number === 0 || number === "0") return true
		return false
	}



	const renderElement = () => {
		switch (steps.step) {
		case 0:
			return <LocalDefaultContainer
				setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
				id={formId}
				couponing={couponing}
			/>
		case 1:
			return <LocalMainForm
				setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
				id={formId}
				couponing={couponing}
				setEarlySubmit={(someValue: any)=>setEarlySubmit(earlySubmitTransformer(someValue))}
			/>
		case 2:
			return <LocalTextForm
				setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
				id={formId}
				couponing={couponing}
			/>
		default :
			return <p>Error</p>
		}
	}

	useEffect(() => {
		if (sendFormState === "success") {
			dispatch(clearValuesMessages())
			if(steps.step !== 3){

				dispatch(changeStep(steps.step + 1))
			}
		} else if (sendFormState === "error") {
			dispatch(clearValuesMessages())
			toast.error(
				<CustomToastContainer
					message={t("warnings.send_error", {number: 1})}
					status={3}/>, {
					autoClose: 2000,
				})
		} else if (sendFormState === "update_success") {
			dispatch(clearValuesMessages())
			toast.success(
				<CustomToastContainer
					message={t("warnings.update_success", {number: 1})}
					status={1}/>, {
					autoClose: 2000,
				})
		} else if (sendFormState === "update_error") {
			dispatch(clearValuesMessages())
			toast.error(
				<CustomToastContainer
					message={t("warnings.send_error", {number: 1})}
					status={1}/>, {
					autoClose: 2000,
				})
		}
	}, [sendFormState])

	// TODO: check editing flow

	// Initialize data from DB
	useEffect(() => {
		if (Object.keys(storedValues).length) {
			const verifyIban = async () => {
				try {
					const isIbanValid = await verifyString(
						storedValues.iban, 
						storedValues.ibanSignature, 
						PUBLIC_KEYS.signature
					)

					if(!isIbanValid){
						alert("THIS IBAN IS COMPROMISED (PROBABLY). TALK TO YOUR DEVELOPERS!")
						return
					}

					const newValues = [
						{
							partner: storedValues.partner,
							iban: atob(storedValues.iban),
							accountHolder: storedValues.accountHolder ?? "", 
							offer: storedValues.offer,
							country: storedValues.country,
							startDate: moment(storedValues.startDate).toDate(),
							endDate: moment(storedValues.endDate).toDate(),
							contact: storedValues.contact,
							localMerchantUID: storedValues.localMerchantUID,
						},
						{
							text: storedValues.questions[0]?.selectedIndex?.toString(),
							image: storedValues.questions[1]?.selectedIndex?.toString(),
							uploadedImages: storedValues.questions[1]?.images,
							uploadedImagesDE: storedValues.questions[1]?.imagesDE,
							uploadedImagesKeys: storedValues.questions[1]?.images,
							uploadedImagesKeysDE: storedValues.questions[1]?.imagesDE,
							featureImage: storedValues.questions[2]?.images,
							featureImageDE: storedValues.questions[2]?.imagesDE,
							featureImageKeys: storedValues.questions[2]?.images,
							featureImageKeysDE: storedValues.questions[2]?.imagesDE,
							logoImage: storedValues.questions[3]?.images,
							logoImageKeys: storedValues.questions[3]?.images,
							options: storedValues.questions[4]?.selectedIndex.toString(),
							optionsDE: storedValues.questions[4]?.selectedIndexDE?.toString(),
							optionsSub: storedValues.questions[4]?.nestedText,
							optionsSubDE: storedValues.questions[4]?.nestedTextDE,
							optionsFile: storedValues.questions[4]?.images,
							optionsFileDE: storedValues.questions[4]?.imagesDE,
							optionsFileKeys: storedValues.questions[4]?.images,
							optionsFileKeysDE: storedValues.questions[4]?.imagesDE,
							linksSub: storedValues.questions[5]?.nestedText,
							linksSubDE: storedValues.questions[5]?.nestedTextDE,
							tags: storedValues.questions[6]?.selectedIndex.toString(),
							tagsSub: storedValues.questions[6]?.nestedText,
							videos: storedValues.questions[7]?.selectedIndex.toString(),
							videosDE: storedValues.questions[7]?.selectedIndex.toString(),
							customVideo: storedValues.questions[7]?.nestedText,
							customVideoDE: storedValues.questions[7]?.nestedText,
							maxTotal: storedValues.questions[18]?.maxTotalType,
							maxTotalUserDefined: storedValues
								.questions[18]?.maxTotalUserDefined ? 
								Number(storedValues.questions[18]?.maxTotalUserDefined) : 
								undefined,
							maxVoucher: storedValues.questions[19]?.maxVoucherType,
							maxVoucherUserDefined: storedValues
								.questions[19]?.maxVoucherUserDefined ? 
								Number(storedValues.questions[19]?.maxVoucherUserDefined) : 
								undefined,
							conditions: storedValues.questions[21]?.conditions
						},
						{
							title: storedValues.questions[8]?.nestedText,
							titleDE: storedValues.questions[8]?.nestedTextDE,
							description: storedValues.questions[9]?.nestedText,
							descriptionDE: storedValues.questions[9]?.nestedTextDE,
							text: storedValues.questions[10]?.nestedText,
							textDE: storedValues.questions[10]?.nestedTextDE,
							highlights: storedValues.questions[11]?.nestedText,
							highlightsDE: storedValues.questions[11]?.nestedTextDE,
							conditions: storedValues.questions[12]?.nestedText,
							conditionsDE: storedValues?.questions[12]?.nestedTextDE,
							additionalConditions: storedValues?.questions[13]?.nestedText,
							additionalConditionsDE: storedValues?.questions[13]?.nestedTextDE,
							merchant: storedValues?.questions[14]?.nestedText,
							merchantDE: storedValues?.questions[14]?.nestedTextDE,
							localMerchantName: storedValues?.questions[15]?.nestedText,
							localMerchantAddress: storedValues?.questions[16]?.nestedText,
							localMerchantWebpage: storedValues?.questions[17]?.nestedText,
							phoneNumber: storedValues?.questions[20].phoneNumber
						}
					]
					dispatch(changeValues(newValues, 0))
				} catch (error) {
					console.log(error)
				}
			}

			if(storedValues.iban){
				verifyIban()
			}

			const newValues = [
				{
					partner: storedValues.partner,
					offer: storedValues.offer,
					country: storedValues.country,
					startDate: moment(storedValues.startDate).toDate(),
					endDate: moment(storedValues.endDate).toDate(),
					contact: storedValues.contact,
					localMerchantName: storedValues?.questions[15]?.nestedText,
					localMerchantAddress: storedValues?.questions[16]?.nestedText,
					localMerchantWebpage: storedValues?.questions[17]?.nestedText,
					phoneNumber: storedValues?.questions[20].phoneNumber
				},
				{  
					text: storedValues.questions[0]?.selectedIndex?.toString(),
					image: storedValues.questions[1]?.selectedIndex?.toString(),
					uploadedImages: storedValues.questions[1]?.images,
					uploadedImagesDE: storedValues.questions[1]?.imagesDE,
					uploadedImagesKeys: storedValues.questions[1]?.images,
					uploadedImagesKeysDE: storedValues.questions[1]?.imagesDE,
					featureImage: storedValues.questions[2]?.images,
					featureImageDE: storedValues.questions[2]?.imagesDE,
					featureImageKeys: storedValues.questions[2]?.images,
					featureImageKeysDE: storedValues.questions[2]?.imagesDE,
					logoImage: storedValues.questions[3]?.images,
					logoImageKeys: storedValues.questions[3]?.images,
					options: storedValues.questions[4]?.selectedIndex.toString(),
					optionsDE: storedValues.questions[4]?.selectedIndexDE?.toString(),
					optionsSub: storedValues.questions[4]?.nestedText,
					optionsSubDE: storedValues.questions[4]?.nestedTextDE,
					optionsFile: storedValues.questions[4]?.images,
					optionsFileDE: storedValues.questions[4]?.imagesDE,
					optionsFileKeys: storedValues.questions[4]?.images,
					optionsFileKeysDE: storedValues.questions[4]?.imagesDE,
					linksSub: storedValues.questions[5]?.nestedText,
					linksSubDE: storedValues.questions[5]?.nestedTextDE,
					tags: storedValues.questions[6]?.selectedIndex.toString(),
					tagsSub: storedValues.questions[6]?.nestedText,
					videos: storedValues.questions[7]?.selectedIndex.toString(),
					videosDE: storedValues.questions[7]?.selectedIndex.toString(),
					customVideo: storedValues.questions[7]?.nestedText,
					customVideoDE: storedValues.questions[7]?.nestedText,
					maxTotal: storedValues.questions[18]?.maxTotalType,
					maxTotalUserDefined: storedValues
						.questions[18]?.maxTotalUserDefined ? 
						Number(storedValues.questions[18]?.maxTotalUserDefined) : 
						undefined,
					maxVoucher: storedValues.questions[19]?.maxVoucherType,
					maxVoucherUserDefined: storedValues
						.questions[19]?.maxVoucherUserDefined ? 
						Number(storedValues.questions[19]?.maxVoucherUserDefined) : 
						undefined,
					conditions: storedValues.questions[21]?.conditions
				},
				{
					title: storedValues.questions[8]?.nestedText,
					titleDE: storedValues.questions[8]?.nestedTextDE,
					description: storedValues.questions[9]?.nestedText,
					descriptionDE: storedValues.questions[9]?.nestedTextDE,
					text: storedValues.questions[10]?.nestedText,
					textDE: storedValues.questions[10]?.nestedTextDE,
					highlights: storedValues.questions[11]?.nestedText,
					highlightsDE: storedValues.questions[11]?.nestedTextDE,
					conditions: storedValues.questions[12]?.nestedText,
					conditionsDE: storedValues?.questions[12]?.nestedTextDE,
					additionalConditions: storedValues?.questions[13]?.nestedText,
					additionalConditionsDE: storedValues?.questions[13]?.nestedTextDE,
					merchant: storedValues?.questions[14]?.nestedText,
					merchantDE: storedValues?.questions[14]?.nestedTextDE,
				}
			]
			dispatch(changeValues(newValues, 0))
		}
	}, [storedValues])

	// Show Button loading while sending values
	const [buttonLoading, setButtonLoading] = useState(false)

	// Hide button loading after response
	useEffect(() => {
		if (serverMessages?.length) {
			setButtonLoading(false)
			dispatch(clearValuesMessages())
		}
	}, [serverMessages])

	// Handle back click
	const handleBackButton = async (condition:string) => {
		if (condition === "prev") {
			dispatch(changeStep(steps.step - 1))
		} else if (condition === "next") {
			const submitEarly = steps.step === 1 && earlySubmit
			if (steps.step === 2 || submitEarly) {

				const values:any = {
					type: couponing ? 6 : 4,
					iban: await encryptString(steps.values[0].iban, PUBLIC_KEYS.encryption),
					localMerchantUID: steps.values[0].localMerchantUID,
					authorizedDecisionMaker: steps.values[0].authorizedDecisionMaker,
					acceptTermsConditions: steps.values[0].acceptTermsConditions,
					confirmBindingOrder: steps.values[0].confirmBindingOrder,
					accountHolder: steps.values[0].accountHolder,
					partner: steps.values[0].partner,
					offer: steps.values[0].offer,
					country: steps.values[0].country,
					contact: [
						{
							name: steps.values[0].firstContactRowName,
							email: steps.values[0].firstContactRowEmail
						},
						{
							name: steps.values[0].secondContactRowName,
							email: steps.values[0].secondContactRowEmail
						},
						{
							name: steps.values[0].thirdContactRowName,
							email: steps.values[0].thirdContactRowEmail
						}
					],
					startDate: new Date(), // Not remove
					endDate: new Date(), // Not remove
					status: 0,
					salesType: couponing ? 6 : types,
					questions: [
						{
							questionIndex: 0,
							selectedIndex: +steps.values[1].text,
						},
						{
							questionIndex: 1,
							selectedIndex: +steps.values[1].image,
							images: steps.values[1].uploadedImagesKeys,
							imagesDE: steps.values[1].uploadedImagesKeysDE
						},
						{
							questionIndex: 2,
							images: steps.values[1].featureImageKeys,
							imagesDE: steps.values[1].featureImageKeysDE,
						},
						{
							questionIndex: 3,
							images: steps.values[1].logoImageKeys,
						},
						{
							questionIndex: 4,
							selectedIndex: +steps.values[1].options,
							selectedIndexDE: +steps.values[1].optionsDE,
							subSelectedIndex: +steps.values[1].optionsSub || 0,
							subSelectedIndexDE: +steps.values[1].optionsSubDE,
							nestedText: steps.values[1].optionsSub,
							nestedTextDE: steps.values[1].optionsSubDE,
							images: steps.values[1].optionsFileKeys,
							imagesDE: steps.values[1].optionsFileKeysDE,
						},
						{
							questionIndex: 5,
							nestedText: steps.values[1].linksSub,
							nestedTextDE: steps.values[1].linksSubDE,
						},
						{
							questionIndex: 6,
							selectedIndex: +steps.values[1].tags,
							nestedText: steps.values[1].tagsSub,
						},
						{
							questionIndex: 7,
							selectedIndex: +steps.values[1].videos,
							selectedIndexDE: +steps.values[1].videosDE,
							subSelectedIndex: +steps.values[1].customVideo,
							subSelectedIndexDE: +steps.values[1].customVideoDE,
							nestedText: steps.values[1].customVideo,
							nestedTextDE: steps.values[1].customVideoDE,
						},
						{
							questionIndex: 8,
							nestedText: steps.values[2].title,
							nestedTextDE: steps.values[2].titleDE,
						},
						{
							questionIndex: 9,
							nestedText: steps.values[2].description,
							nestedTextDE: steps.values[2].descriptionDE,
						},
						{
							questionIndex: 10,
							nestedText: steps.values[2].text,
							nestedTextDE: steps.values[2].textDE,
						},
						{
							questionIndex: 11,
							nestedText: steps.values[2].highlights,
							nestedTextDE: steps.values[2].highlightsDE,
						},
						{
							questionIndex: 12,
							nestedText: steps.values[2].conditions,
							nestedTextDE: steps.values[2].conditionsDE,
						},
						{
							questionIndex: 13,
							nestedText: steps.values[2].additionalConditions,
							nestedTextDE: steps.values[2].additionalConditionsDE,
						},
						{
							questionIndex: 14,
							nestedText: steps.values[2].merchant,
							nestedTextDE: steps.values[2].merchantDE
						},
						{
							questionIndex: 15,
							nestedText: steps.values[0].localMerchantName,
							nestedTextDE: steps.values[0].localMerchantName
						},
						{
							questionIndex: 16,
							nestedText: steps.values[0].localMerchantAddress,
							nestedTextDE: steps.values[0].localMerchantAddress
						},
						{
							questionIndex: 17,
							nestedText: steps.values[0].localMerchantWebpage,
							nestedTextDE: steps.values[0].localMerchantWebpage
						},
						{
							questionIndex: 18,
							maxTotalType: steps.values[1].maxTotal,
							maxTotalUserDefined: steps.values[1].maxTotalUserDefined
						},
						{
							questionIndex: 19,
							maxVoucherType: steps.values[1].maxVoucher,
							maxVoucherUserDefined: steps.values[1].maxVoucherUserDefined
						},
						{
							questionIndex: 20,
							phoneNumber: steps.values[0].phoneNumber,
						},
						{
							questionIndex: 21,
							conditions: steps.values[1].conditions,
						}
					],
				}
				if (business) {
					values.business = true
				}
				setButtonLoading(true)
				console.log(values)

				if (formId) {
					dispatch(updateValues(values, formId))
				} else {
					dispatch(sendValues(values))
				}
				dispatch(changeStep(3))
				return
			}
			if (steps.step !== 2) {
				dispatch(changeStep(steps.step + 1))
			}
		}
	}

	const [showInfoScreen, setShowInfoScreen] = useState(1)

	const handleInfoNavigation = () => {
		if (showInfoScreen < 2) {
			setShowInfoScreen(showInfoScreen + 1)
		} else {
			setShowInfoScreen(0)
		}
	}

	console.log(earlySubmit)
	return(
		<main style={{paddingTop: "1px"}}>

			{showInfoScreen > 99
				? <>
					<div
						className="end"
						data-testid="main_component"
						style={{marginBottom: "10px", paddingTop: "20px"}}
					>
						{showInfoScreen === 2
							? <Button
								text={t("main_form.header.btn_back")}
								disabled={buttonLoading}
								secondary={true}
								style={{marginRight: "15px"}}
								onClick={() => setShowInfoScreen(1)}
							/>
							: null
						}
						::::
						<Button
							onClick={() => handleInfoNavigation()}
							text={t("main_form.header.btn")}
							disabled={false}
						/>
					</div>
					<LocalInfoScreen
						business={business}
						step={showInfoScreen}
						couponing={couponing}
					/>
				</>
				: <>
					{steps.step === 0
						? <div
							className="end"
							style={{marginBottom: "10px", paddingTop: "20px"}}
						>
							<Button
								text={t("main_form.header.btn_back")}
								disabled={buttonLoading}
								secondary={true}
								style={{marginRight: "15px"}}
								onClick={() => setShowInfoScreen(2)}
							/>
							<Button
								onClick={() => handleBackButton("next")}
								text={t("main_form.header.btn")}
								disabled={buttonDisabled}
							/>
						</div>
						:
						<div className="main_header">
							<div>
								<h3 className="title_h3" >{t("main_form.header.title")}</h3>
								{business
									? null
									: <>
										<p className="main_form_header_text">
											{t("main_form.header.text")}
										</p>
										<p className="main_form_header_text">
											{t("main_form.header.text_2")}
										</p>
									</>
								}
							</div>
							{steps.step !== 3
								?
								<div className="header_btn_container">
									<Button
										text={t("main_form.header.btn_back")}
										disabled={buttonLoading}
										secondary={true}
										onClick={() => handleBackButton("prev")}
									/>
									{buttonLoading
										?
										<div className="button_placeholder">
											<BeatLoader size="7px" color="white" />
										</div>
										:
										<>
											<Button
												onClick={() => handleBackButton("next")}
												text={steps.step === 2 || earlySubmit
													? t("main_form.header.btn_send")
													: t("main_form.header.btn")}
												disabled={buttonDisabled}
											/>
										</>
									}
								</div>
								: null
							}
						</div>
					}
					<div className={steps.step === 3
					|| steps.step === 0 ? "main_container full" : "main_container"}>

						{steps.step >= 3
							? <SuccessContainer type={0} update={formId} />
							: <>
								{renderElement()}
								{steps.step !== 0
									? <LocalExamplePage id={formId} couponing={couponing} />
									: null
								}
							</>
						}
					</div>
				</>
			}
			<Footer type={types === 5 ? 5 : 1} />

			<div>
				<PopupWidget  
					url="https://calendly.com/carina-bulz"
					rootElement={document.getElementById("root") as any}
					text="Brauchst du Hilfe? Buche dir hier einen Termin!"
					textColor="#ffffff"
					color="black"
				/>
			</div>
		</main>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		steps: state.steps,
		sendFormState: state.steps.message,
		storedValues: state.steps.valuesFromDB,
		serverMessages: state.steps.message
	}
}

export default connect(mapStateToProps, null)(LocalMain)
